.main-navigation-menu-btn {
    width: 3rem;
    height: 3rem;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 2rem;
    cursor: pointer;
    margin-top: 8px;
    align-items: center;
}

.main-navigation-menu-btn span {
    display: block;
    width: 3rem;
    height: 6px;
    background: white;
}

.main-navigation-title {
    color: white;
}

.main-navigation-title a {
    text-decoration: none;
    color: white;
}

.main-navigation-header-nav {
    display: none;
}

@media (min-width: 768px) {
    .main-navigation-menu-btn {
        display: none;
    }

    .main-navigation-header-nav {
        display: block;
    }
}

.main-header {
    width: 100%;
    height: 4rem;
    display: flex; /* Changed from 'block' to 'flex' */
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(103, 93, 93, 0.29);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    padding: 0 1rem;
    z-index: 5;
}

.main-header > * {
    flex: 0 1 auto;
}

.main-header .main-navigation-header-nav {
    flex: 1;
    text-align: center;
}

main {
    margin-top: 5rem;
}

.drawer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .join-nav {
        display: none;
    }
    .join-drawer {
        margin-left: auto;
        margin-right: auto;
    }
}
