.medical-branch-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    align-items: center;
    gap: 1px;


    @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.difficulty-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    gap: 1px;

    @media screen and (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.game-type-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    gap: 1px;

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

h1 {
    color: white;
    padding: 32px 16px 16px 16px;
    text-align: center;
}

ol {
    white-space: pre-line;
}

.disclaimer {
    color: white;
    text-align: justify;
    margin: 32px;
    font-size: 0.8em;
}

.native-ad {

}