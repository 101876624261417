.failure-card {
    display: inline-block;
    background-color: #edd4d4;
    color: #c41212;
    border: 1px solid #e6c3c3;
    border-radius: 12px;
    padding: 16px;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 16px auto;
    text-align: center;
}

.failure-card p {
    margin: 0;
}