.root-card {
    background: rgba(255, 255, 255, 0.16);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin: 10% 10%;
    padding: 16px;
}

h2 {
    color: white;
    padding: 32px 16px 16px 16px;
    text-align: center;
}

@media (min-width: 768px) {
    .root-card {
        margin: 10% 20%;
    }
}

@media (min-width: 992px) {
    .root-card {
        margin: 10% 30%;
    }
}

.about-text  {
    color: white
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-header {
    color: white;
}