.toast {
    background-color: #333;
    color: #fff;
    padding: 20px;
    margin: 10px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.message {
    flex-grow: 1;
}

.dismiss {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-left: 40px;
    width: 20px;
}
