.root-card-moderator {
    background: rgba(255, 255, 255, 0.16);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin: 5% 10%;
}

.moderator-header {
    color: white;
    margin-left: 16px;
}

li {
    color: white;
}

.diagnosis-challenge-story {
    color: white;
    margin: 16px;
    font-size: 1.1rem;
    text-align: justify;
}

.moderator-bottom-cta {
    display: flex;
    justify-content: space-evenly;
}