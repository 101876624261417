.guesser-root-card {
    background: rgba(255, 255, 255, 0.16);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin: 8% 10% 0 10%;
}

.guesser-story {
    color: white;
    margin: 32px;
    text-align: justify;
    font-size: 1.1em;
}