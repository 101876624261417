.success-card {
    display: inline-block;
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    border-radius: 12px;
    padding: 16px;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 16px auto;
    text-align: center;
}

.success-card p {
    margin: 0;
}