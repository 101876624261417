.lobby-pass-input {
    width: 80%;
    left: 50%;
    height: 60px;
    margin: 16px 16px 32px 16px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.05);
    font-size: 2em;
    color: white;
    text-align: center;
}

.lobby-pass-input:focus {
    outline: none !important;
    border: 1px solid rgba(255, 255, 255, 0.8);
}