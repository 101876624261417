.center-button {
    cursor: pointer;
    border: 1px solid #292929;
    background: #292929;
    color: white;
    padding: 8px 64px;
    margin-bottom: 64px;
    font: inherit;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    border-radius: 11px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.horizontal-align {
    display: flex;
    align-items: center; /* Vertically centers the items */
    justify-content: center; /* Horizontally centers the items, if needed */
}

.horizontal-align span {
    margin-left: 10px; /* Adds some space between the DotLoader and the text */
}

button:disabled,
button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}