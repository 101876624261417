.root-card {
    background: rgba(255, 255, 255, 0.16);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin: 10% 10%;
}

h2 {
    color: white;
    padding: 32px 16px 16px 16px;
    text-align: center;
}

@media (min-width: 768px) {
    .root-card {
        margin: 10% 20%;
    }
}

@media (min-width: 992px) {
    .root-card {
        margin: 10% 30%;
    }
}

.input-join {
    overflow-wrap: break-word;
    width: 100%;
    height: 60px;
    margin: 16px 32px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.05);
    font-size: 1.2em;
    text-align: center;
    color: white;
}

.input-join::placeholder {
    color: white;
}