.join-challenge-component {
    display: flex;
}

.join-challenge-component a {
    border: 1px solid transparent;
    text-decoration: none;
    padding: 0.5rem;
    color: white;
}

.join-challenge-component a:hover,
.join-challenge-component a.active {
    background: rgba(103, 93, 93, 0.29);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    color: #ffffff;
    border-radius: 11px;
}

.join-challenge-component button {
    cursor: pointer;
    border: 1px solid white;
    color: #292929;
    background: transparent;
    padding: 0.5rem 0;
    font: inherit;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    border-radius: 11px;
}

.join-challenge-component button:focus {
    outline: none;
}

.join-challenge-component button:hover,
.join-challenge-component button:active {
    background: rgba(103, 93, 93, 0.29);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    color: #292929;
}

@media (min-width: 976px) {
    .join-challenge-component {
        margin-right: 48px;
    }
}

@media (min-width: 768px) {
    .join-challenge-component a:hover,
    .join-challenge-component a.active {
        background: rgba(103, 93, 93, 0.5);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
        color: #ffffff;
        border-radius: 11px;
    }

    .join-challenge-component button:hover,
    .join-challenge-component button:active {
        background: rgba(103, 93, 93, 0.29);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
        color: #292929;
    }

    .join-challenge-component {
        margin-right: 24px;
    }
}
