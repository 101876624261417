.rules-game-card {
    width: 60%;
    margin: 64px auto 64px auto;
    overflow: hidden;
    background: rgba(103, 93, 93, 0.29);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(103, 93, 93, 0.09);
    text-align: center;
    color: white;
    transition: transform 0.3s ease-in-out;
}

ol {
    margin: 16px 16px 32px;
    text-align: justify;
    font-size: 1.25em;
}