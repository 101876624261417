.card {
    width: 140px;
    height: 120px;
    margin: 16px;
    padding: 2rem;
    overflow: hidden;
    background: rgba(103, 93, 93, 0.29);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(103, 93, 93, 0.09);
    text-align: center;
    color: white;
    transition: transform 0.3s ease-in-out;
}

.card-active {
    width: 140px;
    height: 120px;
    margin: 16px;
    padding: 2rem;
    overflow: hidden;
    background: rgba(103, 93, 93, 0.29);
    border-radius: 16px;
    box-shadow: 0 0 .1rem #fff,
    0 0 .1rem #fff,
    0 0 1rem #905afd,
    0 0 0.4rem #6913fe,
    0 0 1.4rem #4e13fe,
    inset 0 0 0.7rem #2a13fe;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.09);
    text-align: center;
    color: white;
    transition: transform 0.3s ease-in-out;
}

.card:hover {
    transform: scale(1.05);
}

h4 {
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}
