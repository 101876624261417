.root-card-guesser {
    background: rgba(255, 255, 255, 0.16);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin: 5% 10%;
    text-align: center;
}

.answer-center {
    display: flex;
    justify-content: center;
    margin: 10px 10px 0 10px;
}

.chat-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 10px;
}

.chat-message {
    padding: 10px;
    border-radius: 8px;
    max-width: 60%;
    word-wrap: break-word;
}

.chat-message.user {
    background-color: #d1e7dd;
    align-self: flex-end;
    text-align: right;
}

.chat-message.api {
    background-color: #f8d7da;
    align-self: flex-start;
    text-align: left;
}

.diagnosis-challenge-story {
    color: white;
    margin: 16px;
    font-size: 1.1rem;
}

.input-answer {
    overflow-wrap: break-word;
    width: 100%;
    height: 60px;
    margin: 16px 16px 16px 6px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.05);
    font-size: 1.2em;
    text-align: center;
    color: white;
}

.input-answer::placeholder {
    color: white;
}

.single-player-bottom-cta {
    display: flex;
    justify-content: space-evenly;
    margin-top: 64px;
}

.diagnosis-challenge-treatment {
    color: white;
    margin: 16px 16px 16px 36px;
    font-size: 1.1rem;
    text-align: justify;
}

.diagnosis-challenge-answer-headers {
    display: flex;
    justify-content: left;
}