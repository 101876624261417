.nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.nav-links li {
    margin: 1rem;
    display: inline-block;
}

.nav-links a {
    border: 1px solid transparent;
    color: #292929;
    text-decoration: none;
    padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a.active {
    background: rgba(103, 93, 93, 0.29);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    color: #ffffff;
    border-radius: 11px;
}

.nav-links a {
    color: white;
    text-decoration: none;
}

@media (min-width: 768px) {
    .nav-links {
        flex-direction: row;
    }

    .nav-links li {
        margin: 0 0.5rem;
    }

    .nav-links a:hover,
    .nav-links a.active {
        background: rgba(103, 93, 93, 0.5);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
        color: #ffffff;
        border-radius: 11px;
    }
}
