.chat-component {
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 40px;
}

.chat-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 10px;
}

.chat-message {
    padding: 10px;
    border-radius: 8px;
    max-width: 60%;
    word-wrap: break-word;
}

.chat-message.user {
    background-color: #d1e7dd;
    align-self: flex-end;
    text-align: right;
}

.chat-message.api {
    background-color: #f8d7da;
    align-self: flex-start;
    text-align: left;
}

.chat-center {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.center-button-chat {
    cursor: pointer;
    border: 1px solid #292929;
    background: #292929;
    color: white;
    padding: 8px 24px;
    font: inherit;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    border-radius: 11px;
    height: 66px;
}

.input-chat {
    overflow-wrap: break-word;
    width: 100%;
    height: 60px;
    margin: 16px 16px 16px 6px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.05);
    font-size: 1.2em;
    text-align: center;
}

.input-chat:focus {
    outline: none !important;
    border: 1px solid rgba(255, 255, 255, 0.8);
}

.questions-left {
    display: flex;
    justify-content: left;
}