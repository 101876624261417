.games-left-component {
    display: flex;
}

.games-left-text {
    color: white;
    margin-left: 16px;
}

.coins-img {
    width: 36px;
    height: 36px;
}